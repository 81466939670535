/* src/App.css */

/* ================================
   Base Styles
   ================================ */
   body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: var(--color-background);
    color: var(--color-text);
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
    overflow-x: hidden;
  }
  
  main {
    flex: 1;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: var(--color-background);
    color: var(--color-text);
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 100%;
    overflow-x: hidden;
  }
  
  /* ================================
     Buttons
     ================================ */
  .cta-button,
  .testflight-button {
    display: inline-block;
    background-color: transparent;
    color: var(--color-text);
    padding: 0.8rem 1.5rem;
    text-decoration: none;
    border: 2px solid var(--color-primary);
    border-radius: 4px;
    font-weight: bold;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
  }
  
  .cta-button:hover,
  .testflight-button:hover {
    background-color: var(--color-primary);
    color: var(--color-background);
    border-color: var(--color-primary);
  }
  
  /* ================================
     Dark Theme
     ================================ */
  body.dark {
    background-color: var(--color-background);
    color: var(--color-text);
  }
  
  body.dark .how-it-works {
    background-color: #262626;
    color: #ffffff;
  }
  
  body.dark .cta-button,
  body.dark .testflight-button {
    color: var(--color-background);
  }
  
  body.dark .cta-button:hover,
  body.dark .testflight-button:hover {
    background-color: var(--color-primary);
    color: var(--color-background);
  }
  
  /* ================================
     Layout Components
     ================================ */
  .hero,
  .features,
  .screenshots {
    margin: 0;
    padding: 2rem 0;
    position: relative;
  }
  
  .hero-content,
  .features .ant-row,
  .screenshots .ant-carousel {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  /* ================================
     General Section Styles
     ================================ */
  section {
    padding: 2rem 0;
    width: 100%;
    box-sizing: border-box;
  }
  
  h1,
  h2,
  h3 {
    margin-bottom: 1rem;
  }
  
  a {
    color: var(--color-primary);
    transition: color 0.3s ease;
    text-decoration: none;
  }
  
  a:hover {
    color: var(--color-accent);
  }
/* src/components/ThemeSwitcher.css */

/* ================================
   Theme Switcher Button Styles
   ================================ */
   .theme-switcher {
    background: transparent;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0.5rem;
    margin-left: 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
    color: #808080; /* Grayscale color */
    border: none;
    filter: grayscale(100%); /* Make the button grayscale */
  }
  
  .theme-switcher:hover {
    background-color: transparent;
    color: #ffffff;
    filter: grayscale(0%); /* Remove grayscale on hover */
  }
  
  body.dark .theme-switcher {
    border: none;
  }

  
  .theme-switcher:active {
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .drawer-theme-switcher .theme-switcher {
      border: none;
    }
  
    body.dark .drawer-theme-switcher .theme-switcher {
      border: none;
    }
  }
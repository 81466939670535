/* src/components/Hero.css */

/* ================================
   Hero Section Styles
   ================================ */
   #root, .App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  section#hero {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 2rem 0;
  }
  
  .hero {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 2rem 1rem;
    background-color: var(--color-primary);
  }
  
  .hero-content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .hero-logo {
    width: 200px;
    height: auto;
    margin-bottom: 2rem;
  }
  
  .hero h1 {
    font-size: 4.5rem;
    font-weight: 700;
    color: var(--color-text);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .hero p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: var(--color-text);
    margin-bottom: 2rem;
    text-align: center;
  }
  
  /* ================================
     Button Group Styles
     ================================ */
  .button-group {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /* ================================
     Join Testflight & Discord Button Styles
     ================================ */
  .cta-button,
  .testflight-button,
  .discord-button,
  .waitlist-button {
    display: inline-block;
    background-color: transparent;
    color: var(--color-text);
    padding: 0.8rem 1.5rem;
    text-decoration: none;
    border: 2px solid var(--color-text); /* Matches text color */
    border-radius: 12px;
    font-weight: bold;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
  }
  
  .discord-button {
    display: flex;
    align-items: center;
  }
  
  .discord-logo {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
  
  /* Hover Effects */
  .cta-button:hover,
  .testflight-button:hover,
  .discord-button:hover,
  .waitlist-button:hover {
    background-color: var(--color-text);
    color: var(--color-primary);
    border-color: var(--color-text);
  }
  
  /* ================================
     Dark Theme Styles
     ================================ */
  body.dark .hero {
    background-color: var(--color-primary);
    color: var(--color-text);
  }
  
  body.dark .hero-content {
    background-color: var(--color-primary);
  }
  
  body.dark .hero h1 {
    color: var(--color-text);
  }
  
  body.dark .hero p {
    color: var(--color-text);
  }
  
  body.dark .cta-button,
  body.dark .testflight-button,
  body.dark .discord-button,
  body.dark .waitlist-button {
    color: var(--color-text);
    border-color: var(--color-text);
  }
  
  body.dark .cta-button:hover,
  body.dark .testflight-button:hover,
  body.dark .discord-button:hover,
  body.dark .waitlist-button:hover {
    background-color: var(--color-text);
    color: var(--color-primary);
  }

/* Donate button styles */
.donate-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: var(--color-text);
  border: 2px solid var(--color-text);
}

.donate-button:hover {
  background-color: var(--color-text);
  color: var(--color-primary);
  border-color: var(--color-text);
}

.kofi-logo {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  filter: none; /* Remove the filter */
}

body.dark .donate-button {
  color: var(--color-text);
  border-color: var(--color-text);
}

body.dark .donate-button:hover {
  background-color: var(--color-text);
  color: var(--color-primary);
}

/* No need for different filter in dark mode */
  
  /* ================================
     Responsive Adjustments
     ================================ */
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 3rem;
    }
  
    .hero p {
      font-size: 1rem;
    }
  
    .hero-content {
      padding: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .hero h1 {
      font-size: 2.5rem;
    }
  
    .hero-content {
      padding: 1rem;
    }
  }
/* src/components/Features.css */

/* ================================
   Features Section Styles
   ================================ */
   .features {
    color: var(--color-text);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    width: 100%;
    padding: 2rem 0;
    position: relative;
    /* Add scroll margin to account for the fixed toolbar */
    scroll-margin-top: 80px;
  }
  
  .feature-row {
    max-width: 1200px;
    margin: 0 auto !important;
    justify-content: center;
    width: 100%;
    padding: 0 1rem;
  }
  
  .features .ant-col {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }

  .feature-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
    border: 1px solid var(--color-text); /* Add outline */
    border-radius: 8px;
    box-shadow: var(--shadow-md);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    width: 100%;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
  }
  
  .feature-card .ant-card-head {
    background-color: transparent;
    border-bottom: none;
  }
  
  
  .feature-card .ant-card-head-title {
    color: var(--color-text);
    font-weight: 600;
    font-size: 1.5rem;
  }
  
  .feature-card .ant-card-body {
    padding: 1rem;
    color: var(--color-text);
    flex-grow: 1;
  }
  
  body.dark .features {
    color: var(--color-text);
  }
  
  body.dark .feature-card {
    background-color: var(--color-background);
    border: 1px solid var(--color-text);
  }
  
  body.dark .feature-card .ant-card-head-title {
    color: var(--color-text);
  }
  
  @media (max-width: 768px) {
    .features {
      padding: 1.5rem 0;
    }
  
    .feature-card .ant-card-head-title {
      font-size: 1.3rem;
    }
  
    .feature-card .ant-card-body {
      padding: 0.75rem;
    }
  }
  
  @media (max-width: 480px) {
    .features {
      padding: 1rem 0;
    }
  
    .feature-card .ant-card-head-title {
      font-size: 1.2rem;
    }
  
    .feature-card .ant-card-body {
      padding: 0.5rem;
    }
  }
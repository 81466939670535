/* src/components/Screenshots.css */

.screenshots {
  background-color: var(--color-primary);
  color: var(--color-text);
  padding: 2rem 0 3rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  margin: 0 auto; /* Center the container */
  width: 100%;
  max-width: 1000px; /* Set desired max-width */
}

.screenshots::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
}

.screenshot-item img {
  width: 100%;
  height: auto;
  border: 2px solid var(--color-accent);
  border-radius: 8px;
  box-shadow: var(--shadow-md);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.screenshot-item img:hover {
  transform: scale(1.05);
  box-shadow: var(--shadow-lg);
}

.screenshots .slick-dots li button {
  background-color: var(--color-primary);
}

.screenshots .slick-dots li.slick-active button {
  background-color: var(--color-accent);
}

.screenshots .ant-carousel {
  width: 100%;
  max-width: none;
  margin: 0;
}

.screenshots .slick-slide {
  padding: 0;
}

.screenshots .slick-slide > div {
  padding: 0 1rem;
}

/* Dark mode styles */
body.dark .screenshots {
  background-color: var(--color-primary);
  color: var(--color-text);
}

body.dark .screenshots::before {
  background: linear-gradient(to right, var(--color-primary), var(--color-primary));
}

body.dark .screenshot-item img {
  border-color: var(--color-accent);
}

body.dark .screenshots .slick-dots li button {
  background-color: var(--color-primary);
}

body.dark .screenshots .slick-dots li.slick-active button {
  background-color: var(--color-accent);
}
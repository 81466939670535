/* src/components/Toolbar.css */

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: background-color 0.3s ease, padding 0.3s ease;
  padding: 1rem 2rem;
}

.toolbar.scrolled {
  background-color: var(--toolbar-scrolled-bg-color);
  backdrop-filter: blur(12px);
}

.toolbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.logo-title {
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.logo-title.scrolled {
  opacity: 1;
}

.logo {
  height: 30px;
  width: auto;
  margin-right: 10px;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  color: var(--color-text);
}

.nav-items-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-items {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 1.5rem;
}

.nav-items li a {
  color: var(--color-text);
  text-decoration: none;
  font-weight: 500;
  transition: border-bottom 0.2s ease;
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
}

.nav-items li a:hover {
  border-bottom: 2px solid var(--color-text);
}

.hamburger-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text);
  font-size: 1.5rem;
  padding: 0.5rem;
}

/* Drawer styles */
.drawer-nav-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.drawer-nav-items li {
  margin: 1rem 0;
}

.drawer-nav-items a {
  color: var(--color-text);
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  transition: border-bottom 0.2s ease;
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
}

.drawer-nav-items a:hover {
  border-bottom: 2px solid var(--color-text);
}

/* Responsive styles */
@media (max-width: 768px) {
  .toolbar-content {
    justify-content: center;
  }

  .nav-items {
    display: none;
  }

  .hamburger-button {
    display: flex;
    align-items: center;
    position: absolute;
    left: 1rem;
  }

  .logo-title {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .logo-title.scrolled {
    opacity: 1;
  }

  .theme-switcher {
    position: absolute;
    right: 1rem;
  }
}

/* Dark mode styles */
body.dark .toolbar {
  background-color: rgba(46, 54, 64, 0);
}

body.dark .toolbar.scrolled {
  background-color: rgba(46, 54, 64, 0.8);
}

body.dark .nav-items li a,
body.dark .drawer-nav-items a,
body.dark .hamburger-button {
  color: var(--color-text);
}

body.dark .nav-items li a:hover,
body.dark .drawer-nav-items a:hover {
  border-bottom-color: var(--color-text);
}

/* Ensure hamburger menu color matches text color */
.hamburger-button svg {
  fill: var(--color-text);
}